import React, { useState, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Modal,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';

const DemonstrativoIRComponent = ({
  associado,
  handleFormSubmit,
  handleCpfChange,
  cpf,
  ano,
  handleAnoChange,
}: {
  associado: any | null;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCpfChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAnoChange: (event: SelectChangeEvent) => void;
  cpf: string;
  ano: string;
}) => {
  const [open, setOpen] = useState(false);
  const [pdfBase64, setPdfBase64] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleOpen = async () => {
    if (!associado || !ano) return;

    setOpen(true);
    setLoadingPdf(true);

    try {
      const urlBase = process.env.REACT_APP_URL_BASE;
      const response = await axios.post(
        `${urlBase}/SYS/services/Vendedor.aspx/ImpostoRendaAssociado`,
        {
          dados: {
            codigo: associado.Id,
            ano: parseInt(ano), // Passa o ano como número
          },
          token: 'YUISKDAMNVJAFTYU134JRB780S67AUHJ3NI2978ADF19814TH1',
        },
      );

      const pdfData = response.data.dados;
      if (pdfData?.trim().length !== 0) {
        setPdfBase64(pdfData);
      } else {
        throw new Error('Demonstrativo vazio');
      }
    } catch (error) {
      console.error('Erro ao buscar demonstrativo:', error);
      setPdfBase64('');
      alert(
        'Erro ao carregar demonstrativo. Verifique se o ano selecionado possui demonstrativo disponível.',
      );
    } finally {
      setLoadingPdf(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPdfBase64('');
  };

  // Gera anos de 2020 até o ano atual
  const gerarAnos = () => {
    const anoAtual = new Date().getFullYear();
    const anos = [];
    for (let i = anoAtual; i >= 2020; i--) {
      anos.push(i);
    }
    return anos;
  };

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Informe o CPF do titular e o ano para buscar o demonstrativo de IR.
      </Typography>
      <form id="form" onSubmit={handleFormSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Número do CPF"
          variant="outlined"
          value={cpf}
          onChange={handleCpfChange}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="ano-select-label">Ano</InputLabel>
          <Select
            labelId="ano-select-label"
            value={ano}
            label="Ano"
            onChange={handleAnoChange}
            required
          >
            {gerarAnos().map(year => (
              <MenuItem key={year} value={year.toString()}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Buscar Demonstrativo
        </Button>
      </form>

      {associado && (
        <Box mt={4} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            disabled={!ano}
          >
            Visualizar Demonstrativo IR {ano && `(${ano})`}
          </Button>
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            width: '80%',
            height: '80%',
          }}
        >
          {loadingPdf ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
              <Typography variant="h6" style={{ marginLeft: '10px' }}>
                Carregando demonstrativo...
              </Typography>
            </Box>
          ) : pdfBase64 ? (
            <iframe
              src={`data:application/pdf;base64,${pdfBase64}`}
              title="Demonstrativo IR"
              width="100%"
              height="100%"
            />
          ) : (
            <Typography variant="h6">
              Não foi possível carregar o demonstrativo.
            </Typography>
          )}
        </div>
      </Modal>
    </>
  );
};

const DemonstrativoIR: React.FC = () => {
  const [cpf, setCpf] = useState('');
  const [ano, setAno] = useState('');
  const [associado, setAssociado] = useState<any | null>(null);

  const handleCpfChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setCpf(event.target.value);
  };

  const handleAnoChange = (event: SelectChangeEvent): void => {
    setAno(event.target.value);
  };

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (!ano) {
      alert('Por favor, selecione o ano.');
      return;
    }

    setAssociado(null);
    const urlBase = process.env.REACT_APP_URL_BASE;

    const cleanedCpf = cpf.replace(/[^\d]+/g, '');
    try {
      const response = await axios.post(
        `${urlBase}/SYS/services/Vendedor.aspx/BuscaAssociado`,
        {
          dados: { cpf: cleanedCpf, incluirDependentes: 0 },
          token: 'YUISKDAMNVJAFTYU134JRB780S67AUHJ3NI2978ADF19814TH1',
          pageRequest: { Page: 1, Size: 1 },
        },
      );

      const dados = response.data.dados.Data;
      if (!dados || dados.length === 0) {
        alert('Nenhum Associado encontrado com este CPF.');
        return;
      }

      setAssociado(dados[0]);
    } catch (error) {
      console.error('Erro ao buscar associado:', error);
      alert('Erro ao buscar associado. Por favor, tente novamente.');
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Paper
          elevation={3}
          style={{ padding: '2rem', maxWidth: '600px', width: '100%' }}
        >
          <Typography variant="h5" component="h3" gutterBottom>
            Demonstrativo de Imposto de Renda
          </Typography>
          <DemonstrativoIRComponent
            associado={associado}
            handleFormSubmit={handleFormSubmit}
            handleCpfChange={handleCpfChange}
            handleAnoChange={handleAnoChange}
            cpf={cpf}
            ano={ano}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default DemonstrativoIR;
